<template>
  <div class="email-app-details show">

    <!-- Ticket Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="goBack"
          />
        </span>
        <h4 class="email-subject mb-0">
          Ticket Escalation Discussion
        </h4>
      </div>
    </div>

    <!-- Ticket Details -->
    <vue-perfect-scrollbar
      id="response-box"
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Ticket Thread -->
      <b-row>
        <b-col
          cols="12"
          class="mt-1"
        >
          <ticket-expert-message-main-card
            :message="ticketData"
          />
        </b-col>
      </b-row>

      <!-- Earlier Ticket Messages -->
      <template v-if="ticketData.discussions.length !== 0">
        <b-row
          v-for="threadMail in ticketData.discussions"
          :key="threadMail.response_id"
        >
          <b-col cols="12">
            <ticket-expert-message-card
              :ticket="ticketData"
              :message="threadMail"
              @add-faq="showAddFaqModal"
            />
          </b-col>
        </b-row>
      </template>

      <!-- Closing Messages -->
      <template v-if="ticketData.status === 'Closed'">
        <b-row>
          <b-col cols="12">
            <b-card
              no-body
              class="w-75"
            >
              <b-card-header
                class="email-detail-head px-1 pb-50 pt-1"
              >
                <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
                  <div class="mail-items">
                    <h5 class="mb-0">
                      Summary
                      <b-link
                        class="small ml-1"
                        @click.prevent="showAddResumeModal(ticketData.resume)"
                      >{{ (ticketData.resume !== null) ? 'Edit' : 'Add' }}</b-link>
                    </h5>
                  </div>
                </div>
              </b-card-header>

              <b-card-body class="mail-message-wrapper p-1">
                <div class="mail-message">
                  {{ (ticketData.resume) ? ticketData.resume : '-' }}
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </template>

    </vue-perfect-scrollbar>

    <div
      v-if="userData.group_id > 1000"
      id="reply-box"
      class="email-detail-footer"
    >
      <!-- Ticket Actions: Reply or Forward -->
      <template v-if="ticketData.status !== 'Closed'">
        <b-col cols="12">
          <b-card class="mb-1">
            <quill-editor
              id="replyQuill"
              ref="myTextEditor"
              v-model="replyText"
              class="editor"
              :options="editorOption"
            />
            <div
              id="quill-toolbar"
              class="d-flex border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-link" />
            </div>
            <!-- BODY -->
            <template v-if="alert">
              <b-alert
                :variant="alertDetail.variant"
                class="my-25"
                show
              >
                <h4 class="alert-heading">
                  {{ alertDetail.title }}
                </h4>
                <div
                  v-if="alertDetail.variant === 'danger'"
                  class="alert-body"
                >
                  <ul class="pl-2">
                    <li
                      v-for="(item, index) in alertDetail.text"
                      :key="index"
                    >
                      <template v-for="txt in item">
                        {{ txt }}
                      </template>
                    </li>
                  </ul>
                </div>
                <div
                  v-else
                  class="alert-body"
                >
                  {{ alertDetail.text }}
                </div>
              </b-alert>
            </template>
            <b-row
              class="mt-50"
              align-v="center"
            >
              <b-col>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="saveReply"
                >
                  Send Reply
                </b-button>
                <b-button
                  size="sm"
                  variant="warning"
                  class="float-right"
                  @click="closeTicket"
                >
                  Close Escalation
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </template>
    </div>

    <portal to="content-renderer-sidebar-right">
      <ticket-expert-right-sidebar
        :message="ticketData.ticket_data"
        @replyupdate="updateFaqReply"
      />
    </portal>

    <b-modal
      id="modal-resume"
      ref="modal-resume"
      title="Escalation Summary"
      ok-title="Save"
      @ok.prevent="saveResume"
    >
      <b-card-text>
        <!-- BODY -->
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="m-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul class="pl-2">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  <template v-for="txt in item">
                    {{ txt }}
                  </template>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form>
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Summary"
              rules="required"
            >
              <b-form-group
                label="Summary"
                label-for="resume"
              >
                <b-form-input
                  id="resume"
                  v-model="resume"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Summary"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-faq"
      ref="modal-faq"
      title="Add FAQ Data"
      ok-title="Save"
      @ok.prevent="saveFaq"
    >
      <b-card-text>
        <!-- BODY -->
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="m-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul class="pl-2">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  <template v-for="txt in item">
                    {{ txt }}
                  </template>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form>
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="FAQ Title"
              rules="required"
            >
              <b-form-group
                label="FAQ Title"
                label-for="faq-title"
              >
                <b-form-input
                  id="faq-title"
                  v-model="newFaq.title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="FAQ Title"
                  autofocus
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Company Name -->
            <validation-provider
              #default="validationContext"
              name="FAQ Answer"
              rules="required"
            >
              <b-form-group
                label="FAQ Answer"
                label-for="faq-answer"
              >
                <quill-editor
                  id="modalQuill"
                  ref="myModalTextEditor"
                  v-model="newFaq.message"
                  class="editor"
                  :options="editorOption"
                />
                <div
                  id="quill-toolbar"
                  class="d-flex border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-link" />
                </div>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BModal, VBModal, BCardText,
  BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BAlert,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useEmail from '../ticket/useEmail'
import TicketExpertMessageMainCard from './TicketExpertMessageMainCard.vue'
import TicketExpertMessageCard from './TicketExpertMessageCard.vue'
import TicketExpertRightSidebar from './TicketExpertRightSidebar.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {

    // BSV
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BCardText,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,
    BFormInput,
    BAlert,

    // 3rd Party
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    quillEditor,

    // SFC
    TicketExpertMessageMainCard,
    TicketExpertMessageCard,
    TicketExpertRightSidebar,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const toast = useToast()
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const userData = getUserData()

    return {
      toast,
      userData,

      refFormObserver,
      getValidationState,

      // UI
      perfectScrollbarSettings,

      // useTicket
      resolveLabelColor,
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      today,
      required,
      email,
      goto: '/inbox/expert',
      esc_id: '',
      replyText: '',
      resume: '',
      ticketData: {
        ticket_data: {
          ticket_number: '-',
        },
        discussions: [],
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar',
        },
      },
      newFaq: {
        title: '',
        message: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/tickets/mine')
    } else {
      next(vm => {
        // eslint-disable-next-line no-param-reassign
        vm.goto = (from) ? from.path : '/tickets/mine'
      })
    }
  },
  created() {
    this.esc_id = this.$route.params.id
    this.getData()
  },
  mounted() {
    this.observeHeight()
  },
  methods: {
    observeHeight() {
      const targetDiv = document.getElementById('reply-box')
      if (targetDiv) {
        const resizeObserver = new ResizeObserver((() => {
          const { offsetHeight } = targetDiv
          this.resizeBox(offsetHeight)
        }))

        resizeObserver.observe(targetDiv)
      }
    },
    resizeBox(height) {
      const div = document.getElementById('response-box')
      if (div) {
        div.style.maxHeight = `calc(100% - ${height + 70}px)`
      }
    },
    toBottom() {
      const targetDiv = document.getElementById('response-box')
      setTimeout(() => {
        targetDiv.scrollTop = targetDiv.scrollHeight
      }, 50)
    },
    showAddFaqModal(value) {
      const div = document.createElement('div')
      div.innerHTML = this.ticketData.message
      const titleText = div.textContent || div.innerText || ''

      this.newFaq = {
        title: titleText,
        message: value,
      }
      this.$refs['modal-faq'].show()
    },
    showAddResumeModal(value) {
      this.resume = value
      this.$refs['modal-resume'].show()
    },
    updateReply(msg) {
      this.replyText = msg
      this.$refs['modal-template'].hide()
    },
    goBack() {
      this.$router.push(this.goto)
    },
    getData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post('/escalate/view', { esc_id: this.esc_id }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.ticketData = resp.data.data
            this.toBottom()
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Ticket Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.$router.push({ name: 'error-404' })
        })
    },
    saveFaq() {
      document.getElementById('loading-bg').style.display = 'block'

      this.$http.post('/faq', this.newFaq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.alert = false
            this.$refs['modal-faq'].hide()

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Add New FAQ Success',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveResume() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        esc_id: this.esc_id,
        resume: this.resume,
      }

      this.$http.post('/escalate/resume', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.alert = false
            this.ticketData.resume = this.resume
            this.resume = ''
            this.$refs['modal-resume'].hide()

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Summary Saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveReply() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        esc_id: this.esc_id,
        message: this.replyText,
      }
      this.$http.post('/escalate/reply', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            // this.ticketData.discussions = resp.data.discussions
            this.replyText = ''
            this.alert = false

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Reply saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            this.getData()
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error submit response',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeTicket() {
      this.$swal({
        title: 'Close This Escalation?',
        html: '<p>you can add optional summary below</p>',
        input: 'text',
        inputPlaceholder: 'Ticket Summary',
        inputAttributes: {
          id: 'resume-input',
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save and close!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const resume = document.getElementById('resume-input').value
        if (result.isConfirmed) {
          document.getElementById('loading-bg').style.display = 'block'
          const target = '/escalate/close'
          const params = {
            esc_id: this.ticketData.esc_id,
            status_id: 2,
          }
          if (resume !== '') {
            params.resume = resume
          }
          this.$http.post(target, params, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                document.getElementById('loading-bg').style.display = 'none'
                this.$swal({
                  icon: 'success',
                  title: 'Update Completed!',
                  text: 'Escalation ticket status has been updated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(errors => {
              document.getElementById('loading-bg').style.display = 'none'
              this.toast({
                component: ToastificationContent,
                props: {
                  title: `Error Updating Data : ${errors.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.getData()
          this.$swal.close()
        }
      })
    },
    updateFaqReply(data) {
      this.replyText = data
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
