<template>
  <b-card
    no-body
    class="w-75"
    :class="(message.author_type === 'author') ? '' : 'float-right'"
    :bg-variant="bgVariant(message)"
    text-variant="dark"
  >
    <b-card-header
      v-if="message.author"
      class="email-detail-head px-1 pb-50 pt-1"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.author }}
            <span class="small">
              {{ message.author_type }}
            </span>
          </h5>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time">
          {{ message.created_at | formatDate }}
          <span v-if="button && message.author !== 'System' && message.author_type !== 'author' && user.group_id !== 90">| </span>
          <b-link
            v-if="button && message.author !== 'System' && message.author_type !== 'author' && user.group_id !== 90"
            @click.prevent="$emit('add-faq', message.message)"
          >Add to Faq</b-link>
        </small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper p-1">
      <p
        v-if="message.response_type === 'escalation_create' || message.response_type === 'escalation_reply'"
        class="small mb-25"
      ><b>{{ message.subject }}</b></p>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.message"
      />
      <!-- eslint-enable -->
      <small class="mail-date-time">
        <b-button
          v-if="message.subject === 'REQUEST_PSE'"
          size="sm"
          variant="outline-info"
          @click.prevent="copyLink"
        >Copy Link Form Data PSE</b-button>
        <b-link
          v-if="button && message.author !== 'System' && (message.response_type == 'escalation_reply' || message.response_type == 'escalation_create') && message.status == 1 && user.group_id !== 90"
          class="btn btn-sm btn-outline-info"
          @click.prevent="$emit('viewescalate', message.parent_id)"
        >Reply</b-link>
      </small>
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Attachment{{ message.attachments.length > 1 ? 's' : '' }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.location"
            target="_blank"
            :class="{'mt-75': index}"
          >
            <b-img
              v-if="attachment.content_type === 'image/jpeg' || attachment.content_type === 'image/png'"
              :src="attachment.location"
              width="16px"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.filename }}</span>
            <span class="text-muted font-small-2 ml-25">({{ attachment.file_size }})</span>
          </b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardFooter, BLink, BImg, BButton,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BCardFooter, BLink, BImg, BButton,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    button: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
  methods: {
    openEscalate(value) {
      this.$emit('viewescalate', value)
    },
    bgVariant(msg) {
      let bgclass = ''
      if (msg.author === 'System') {
        bgclass = 'warning'
      } else if (msg.response_type === 'escalation_create') {
        bgclass = 'light-primary'
      } else if (msg.response_type === 'escalation_reply') {
        bgclass = 'light-info'
      }
      return bgclass
    },
    copyLink() {
      const url = `${process.env.VUE_APP_MAIN_URL}/form/pse/check/${this.ticket.pse.request_code}/ticket/${this.ticket.ticket_number}`
      const textArea = document.createElement('textarea')
      textArea.value = url
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Unable to copy to clipboard', err)
      }
      document.body.removeChild(textArea)
    },
  },
}
</script>
