<template>
  <b-card-text>
    <div class="mb-50">
      <b-input-group>
        <b-form-input
          v-model="searchQuery"
          placeholder="Keyword"
          autofocus
          @keyup.enter="getFaqData"
        />
        <b-input-group-append>
          <b-button
            v-if="searchQuery"
            size="sm"
            variant="outline-primary"
            @click.prevent="clearFaqData"
          >
            <feather-icon
              icon="TrashIcon"
              size="12"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="getFaqData"
          >
            <feather-icon
              icon="SearchIcon"
              size="14"
            />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <!-- eslint-enable -->
    <app-collapse
      v-if="faqList.length !== 0"
      class="mb-1"
    >
      <app-collapse-item
        v-for="faq in faqList"
        :key="faq.faq_id"
        :title="faq.title"
        class="px-0"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="small faq-content-detail"
          v-html="faq.message"
        />
        <!-- eslint-enable -->
        <div
          v-if="faq.attachments && faq.attachments.length"
          class="small ml-75 mb-50"
        >
          <div class="d-flex align-items-center mb-25">
            &middot; {{ faq.attachments.length }} Attachment{{ faq.attachments.length > 1 ? 's' : '' }}
          </div>

          <div class="d-flex flex-column">
            <b-link
              v-for="(attachment, index) in faq.attachments"
              :key="index"
              :href="attachment.location"
              target="_blank"
              :class="{'mt-75': index}"
            >
              <b-form-checkbox
                v-model="selectedAttach"
                name="replyAttachFaq"
                :value="attachment.attachment_id"
                class="custom-control-primary"
              >
                <span class="text-muted align-text-top">{{ attachment.filename }}</span>
                <span class="text-muted ml-25">({{ attachment.file_size }})</span>
              </b-form-checkbox>
            </b-link>
          </div>
        </div>
        <b-button
          v-if="userData.group_id !== 90 && userData.group_id < 1000"
          variant="outline-primary"
          size="sm"
          @click.prevent="copyReko(faq.message)"
        >
          <feather-icon
            icon="CopyIcon"
            size="12"
          /> Add as reply
        </b-button>
      </app-collapse-item>
    </app-collapse>
    <p v-else>
      <i>No related faq yet.</i>
    </p>
  </b-card-text>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardText, BFormInput, BButton, BInputGroup, BInputGroupAppend, BFormCheckbox, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BLink,
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  data() {
    return {
      userData: getUserData(),
      selectedAttach: [],
      faqList: [],
      searchQuery: '',
    }
  },
  mounted() {
    this.getFaqData()
  },
  methods: {
    copyReko(id) {
      this.$emit('replyupdate', id)
      this.$emit('replyattach', this.selectedAttach)
      this.$emit('closemodal', id)
    },
    clearFaqData() {
      this.searchQuery = ''
      this.getFaqData()
    },
    getFaqData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.faqList = []
      const params = {
        length: 25,
        filters: [],
        sort_by: [{
          column: 'created_at',
          asc_desc: 'desc',
        }],
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      this.$http.post('/knowledge', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.faqList = resp.data.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching FAQ list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
