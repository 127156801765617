<template>
  <b-card
    no-body
    class="w-75"
  >
    <b-card-header
      v-if="message.author"
      class="email-detail-head px-1 pb-50 pt-1"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.author }}
          </h5>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ message.created_at | formatDate }}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper p-1">
      <h6>{{ message.subject }}</h6>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.message"
      />
      <!-- eslint-enable -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
  methods: {
  },
}
</script>
