export default function useEmail() {
  const resolveLabelColor = label => {
    if (label === 'Closed') return 'success'
    if (label === 'Open') return 'info'
    if (label === 'On Progress') return 'warning'
    if (label === 'On Hold') return 'danger'
    if (label === 'Spam') return 'secondary'
    return 'primary'
  }

  const resolvePrioColor = label => {
    if (label === 'Urgent') return 'danger'
    if (label === 'High') return 'warning'
    if (label === 'Medium') return 'info'
    return 'secondary'
  }

  const resolveStatColor = status => {
    if (status === 'Active') return 'info'
    return 'secondary'
  }

  return {
    resolveLabelColor,
    resolvePrioColor,
    resolveStatColor,
  }
}
