<template>
  <div class="sidebar-right">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <app-collapse>
              <app-collapse-item
                title="Ticket Info"
                :is-visible="true"
              >
                <div class="mb-50">Ticket ID<br>
                  <small>{{ message.ticket_number }}</small>
                </div>
                <div class="mb-50">Source<br>
                  <small>{{ message.channel }}</small>
                </div>
                <div class="mb-50">Created<br>
                  <small>{{ message.created_at | formatDate }}</small>
                </div>
                <div class="mb-50">Last update<br>
                  <small>{{ message.updated_at | formatDate }}</small>
                </div>

                <div
                  class="form-group-compose text-center compose-btn"
                >
                  <b-button
                    variant="primary"
                    block
                    @click="viewTicket"
                  >
                    View Conversation
                  </b-button>
                  <b-button
                    :to="{ name: 'tickets-view', params: { id: message.ticket_number}}"
                    variant="info"
                    target="_blank"
                    block
                  >
                    Go To Ticket
                  </b-button>
                </div>
              </app-collapse-item>
              <app-collapse-item
                v-if="message.pse && message.pse.email_oss !== null"
                title="Data PSE"
                :is-visible="true"
              >
                <div class="mb-50">Email OSS<br>
                  <small>{{ message.pse.email_oss }}</small>
                </div>
                <div class="mb-50">NIB<br>
                  <small>{{ message.pse.nib }}</small>
                </div>
                <div class="mb-50">KBLI<br>
                  <small>{{ message.pse.kbli }}</small>
                </div>
                <div class="mb-50">ID Izin<br>
                  <small>{{ message.pse.id_izin }}</small>
                </div>
                <div class="mb-50">Kendala<br>
                  <small>{{ message.pse.message }}</small>
                </div>
                <div>Related Files</div>
                <div
                  v-if="message.pse.attachments && message.pse.attachments.length !== 0"
                  class="d-flex flex-column"
                >
                  <b-link
                    v-for="(atc, index) in message.pse.attachments"
                    :key="index"
                    :href="atc.fileurl"
                    target="_blank"
                    class="small"
                    :class="{'mt-25': index}"
                  >
                    {{ atc.filename }}
                  </b-link>
                </div>
              </app-collapse-item>
            </app-collapse>
            <b-button
              v-b-modal.modal-search-faq
              variant="flat-secondary"
              size="lg"
              class="text-left p-1 my-0 border-bottom"
              block
            >FAQ Search</b-button>
            <b-button
              v-b-modal.modal-pse
              variant="flat-secondary"
              size="lg"
              class="text-left p-1 my-0 border-bottom"
              block
            >PSE Search</b-button>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal
      id="modal-escalate"
      ref="modal-escalate"
      title="Ticket Details"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <h3 class="fw-bold mb-1">
          {{ ticketData.subject }}
          <b-button
            :to="{ name: 'tickets-view', params: { id: ticketData.ticket_number}}"
            variant="outline-info"
            size="sm"
            class="ml-1"
            target="_blank"
          >
            view ticket
          </b-button>
        </h3>

        <!-- Ticket Thread -->
        <b-row>
          <b-col cols="12">
            <ticket-message-main-card
              :message="ticketData"
              :button="false"
            />
          </b-col>
        </b-row>

        <!-- Earlier Ticket Messages -->
        <template v-if="ticketData.responses.length !== 0">
          <b-row
            v-for="threadMail in ticketData.responses"
            :key="threadMail.response_id"
          >
            <b-col cols="12">
              <ticket-message-card
                :ticket="ticketData"
                :message="threadMail"
                :user="userData"
                :button="false"
              />
            </b-col>
          </b-row>
        </template>
      </b-card-text>
    </b-modal>

    <!-- modal pse -->
    <b-modal
      id="modal-pse"
      ref="searchpse"
      title="Search PSE Data"
      size="lg"
      hide-footer
    >
      <ticket-pse />
    </b-modal>

    <!-- modal search faq -->
    <b-modal
      id="modal-search-faq"
      ref="searchfaq"
      title="Search FAQ"
      size="lg"
      hide-footer
    >
      <ticket-faq
        @replyupdate="copyReko"
      />
    </b-modal>
    <!-- modal -->
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton, VBModal, BRow, BCol, BCardText, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TicketMessageMainCard from '../ticket/TicketMessageMainCard.vue'
import TicketMessageCard from '../ticket/TicketMessageCard.vue'
import TicketPse from '../ticket/module/TicketPse.vue'
import TicketFaq from '../ticket/module/TicketFaq.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    // BSV
    BButton,
    BRow,
    BCol,
    BCardText,
    BLink,
    VuePerfectScrollbar,
    TicketMessageMainCard,
    TicketMessageCard,
    TicketPse,
    TicketFaq,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Pending') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      toast,

      resolveFilterBadgeColor,
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      userData: getUserData(),
      ticketData: {
        author: {},
        responses: [],
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    copyReko(id) {
      this.$emit('replyupdate', id)
      this.$refs['modal-faq-search'].hide()
    },
    viewTicket() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post('/ticket/show', { ticket_number: this.message.ticket_number }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.ticketData = resp.data.data
            this.$refs['modal-escalate'].show()
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Ticket Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
