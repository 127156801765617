<template>
  <b-card
    no-body
    class="w-75"
  >
    <b-card-header
      v-if="message.author"
      class="email-detail-head px-1 pb-50 pt-1"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <div class="mail-items">
          <h5 class="mb-0">
            <b-link
              :to="{ name:'author-history', params: { id: message.author.author_id} }"
              target="_blank"
            >
              {{ message.author.name }}
            </b-link>
            <small>From
              <b-link
                :to="{ name:'author-company', params: { id: message.author.company_name} }"
                target="_blank"
              >
                <strong>{{ (message.author.company_name !== null) ? message.author.company_name : '-' }}</strong>
              </b-link>
            </small>
          </h5>
          <span class="mr-50">
            <feather-icon
              icon="MailIcon"
              size="14"
            /> {{ (message.author.email !== null) ? message.author.email : '&mdash;' }}</span>
          <span class="mr-50">
            <feather-icon
              icon="PhoneIcon"
              size="14"
            /> {{ (message.author.phonenumber !== null) ? message.author.phonenumber : '&mdash;' }}</span>
          <span class="mr-50">
            <b-link
              v-if="button"
              class="text-warning"
              @click.prevent="$emit('edit-author')"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
            </b-link>
          </span>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ message.created_at | formatDate }}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper p-1">
      <h6>{{ message.subject }}</h6>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.message"
      />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Attachment{{ message.attachments.length > 1 ? 's' : '' }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.location"
            target="_blank"
            :class="{'mt-75': index}"
          >
            <b-img
              v-if="attachment.content_type === 'image/jpeg' || attachment.content_type === 'image/png'"
              :src="attachment.location"
              width="16px"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.filename }}</span>
            <span class="text-muted font-small-2 ml-25">({{ attachment.file_size }})</span>
          </b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardFooter, BLink, BImg,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BCardFooter, BLink, BImg,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    button: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
  methods: {
  },
}
</script>
