<template>
  <b-card-text>
    <div class="mb-50">
      <b-input-group>
        <b-form-input
          v-model="pseSearchQuery"
          placeholder="Keyword"
          autofocus
          @keyup.enter="getPseData"
        />
        <b-input-group-append>
          <b-button
            v-if="pseSearchQuery"
            size="sm"
            variant="outline-primary"
            @click.prevent="clearPseData"
          >
            <feather-icon
              icon="TrashIcon"
              size="12"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="getPseData"
          >
            <feather-icon
              icon="SearchIcon"
              size="14"
            />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <!-- eslint-enable -->
    <app-collapse
      v-if="pseList.length !== 0"
      class="mb-1"
    >
      <app-collapse-item
        v-for="pseDetail in pseList"
        :key="pseDetail.id"
        :title="`${pseDetail.nomor_permohonan}: ${pseDetail.nama_sistem_elektronik}`"
        class="px-0"
      >
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Sistem Elektronik
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_sistem_elektronik }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Alamat Website
          </b-col>
          <b-col cols="7">
            {{ pseDetail.alamat_website }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Pemohon
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_pemohon }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Badan Hukum
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_badan_hukum }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            NIB
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nib }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor PB Umku
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_pb_umku }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor Permohonan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_permohonan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor TDPSE
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_tdpse }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Lokalitas
          </b-col>
          <b-col cols="7">
            {{ pseDetail.lokalitas }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Jenis Permohonan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.jenis_permohonan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Kategori Layanan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.kategori_layanan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Proses Berjalan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.proses_berjalan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Status
          </b-col>
          <b-col cols="7">
            {{ pseDetail.status }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Verifikator
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_verifikator }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Status Verifikasi
          </b-col>
          <b-col cols="7">
            {{ pseDetail.status_verifikasi }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Tanggal Pengajuan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.tanggal_pengajuan | formatDate }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Tanggal Terbit
          </b-col>
          <b-col cols="7">
            {{ pseDetail.tanggal_terbit | formatDate }}
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
    <p v-else>
      <i>No related PSE Data yet.</i>
    </p>
  </b-card-text>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCardText, BFormInput, BButton, BCol, BRow, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  data() {
    return {
      pseList: [],
      pseSearchQuery: '',
    }
  },
  methods: {
    clearPseData() {
      this.pseSearchQuery = ''
      this.pseList = []
    },
    getPseData() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        length: 100,
        page: 1,
      }
      if (this.pseSearchQuery !== '') {
        params.keyword = this.pseSearchQuery
      }
      this.$http.post('/pse/search', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'
            this.pseList = resp.data.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching PSE data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
